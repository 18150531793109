/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InitiateSellTransactionDetails
 */
export interface InitiateSellTransactionDetails {
    /**
     * Opaque entity identifier.
     * @type {string}
     * @memberof InitiateSellTransactionDetails
     */
    quoteId: string;
    /**
     * The source crypto address(es) from which, when transaction is approved, the cryptocurrency will be sent on the blockchain.
     * Simplex uses these to run preliminary risk, policy and compliance checks.
     * @type {Array<string>}
     * @memberof InitiateSellTransactionDetails
     */
    sourceCryptoAddresses?: Array<string>;
    /**
     * A string representing a crypto wallet address.
     * @type {string}
     * @memberof InitiateSellTransactionDetails
     */
    refundCryptoAddress?: string;
}

/**
 * Check if a given object implements the InitiateSellTransactionDetails interface.
 */
export function instanceOfInitiateSellTransactionDetails(value: object): value is InitiateSellTransactionDetails {
    if (!('quoteId' in value) || value['quoteId'] === undefined) return false;
    return true;
}

export function InitiateSellTransactionDetailsFromJSON(json: any): InitiateSellTransactionDetails {
    return InitiateSellTransactionDetailsFromJSONTyped(json, false);
}

export function InitiateSellTransactionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitiateSellTransactionDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'quoteId': json['quote_id'],
        'sourceCryptoAddresses': json['source_crypto_addresses'] == null ? undefined : json['source_crypto_addresses'],
        'refundCryptoAddress': json['refund_crypto_address'] == null ? undefined : json['refund_crypto_address'],
    };
}

  export function InitiateSellTransactionDetailsToJSON(json: any): InitiateSellTransactionDetails {
      return InitiateSellTransactionDetailsToJSONTyped(json, false);
  }

  export function InitiateSellTransactionDetailsToJSONTyped(value?: InitiateSellTransactionDetails | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'quote_id': value['quoteId'],
        'source_crypto_addresses': value['sourceCryptoAddresses'],
        'refund_crypto_address': value['refundCryptoAddress'],
    };
}

