/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InitiateSellResponse
 */
export interface InitiateSellResponse {
    /**
     * transaction identifier
     * @type {string}
     * @memberof InitiateSellResponse
     */
    txnId: string;
    /**
     * The URL where the checkout flow will take place. You should direct the end-user’s browser there, in either a new tab, an iframe, or a webview in your app.
     * In case of an error txn_url will not be returned.
     * @type {string}
     * @memberof InitiateSellResponse
     */
    txnUrl: string;
}

/**
 * Check if a given object implements the InitiateSellResponse interface.
 */
export function instanceOfInitiateSellResponse(value: object): value is InitiateSellResponse {
    if (!('txnId' in value) || value['txnId'] === undefined) return false;
    if (!('txnUrl' in value) || value['txnUrl'] === undefined) return false;
    return true;
}

export function InitiateSellResponseFromJSON(json: any): InitiateSellResponse {
    return InitiateSellResponseFromJSONTyped(json, false);
}

export function InitiateSellResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitiateSellResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'txnId': json['txn_id'],
        'txnUrl': json['txn_url'],
    };
}

  export function InitiateSellResponseToJSON(json: any): InitiateSellResponse {
      return InitiateSellResponseToJSONTyped(json, false);
  }

  export function InitiateSellResponseToJSONTyped(value?: InitiateSellResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'txn_id': value['txnId'],
        'txn_url': value['txnUrl'],
    };
}

