import type { InitiateSellResponse } from '@simplex/rnd.api-client-sell-checkout';
import { useMutation } from '../app/api/use-mutation';

export function useInitiateSell() {
  return useMutation<InitiateSellResponse, { quoteId: string; ref?: string }>(`initiate-sell`, (api, req) => {
    const parentUrl = window.location !== window.parent.location ? document.referrer : document.location.href;
    return api.initiateSell({
      initiateSell: {
        refererUrl: parentUrl,
        returnUrl: parentUrl,
        txnDetails: {
          quoteId: req.quoteId,
        },
      },
      ref: req.ref,
    });
  });
}
