/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PersonalDetailsAddressesInner } from './PersonalDetailsAddressesInner';
import {
    PersonalDetailsAddressesInnerFromJSON,
    PersonalDetailsAddressesInnerFromJSONTyped,
    PersonalDetailsAddressesInnerToJSON,
    PersonalDetailsAddressesInnerToJSONTyped,
} from './PersonalDetailsAddressesInner';

/**
 * 
 * @export
 * @interface AccountDetailsPersonalDetails
 */
export interface AccountDetailsPersonalDetails {
    /**
     * The first name on the account.
     * @type {string}
     * @memberof AccountDetailsPersonalDetails
     */
    firstName?: string;
    /**
     * The last name on the account.
     * @type {string}
     * @memberof AccountDetailsPersonalDetails
     */
    lastName?: string;
    /**
     * A list of account email addresses, starting with the account’s primary email address.
     * @type {Array<string>}
     * @memberof AccountDetailsPersonalDetails
     */
    emails: Array<string>;
    /**
     * A list of account phone numbers, starting with the account’s primary phone number.
     * @type {Array<string>}
     * @memberof AccountDetailsPersonalDetails
     */
    phones: Array<string>;
    /**
     * A list of account mailing addresses, starting with the account’s primary address.
     * @type {Array<PersonalDetailsAddressesInner>}
     * @memberof AccountDetailsPersonalDetails
     */
    addresses: Array<PersonalDetailsAddressesInner>;
}

/**
 * Check if a given object implements the AccountDetailsPersonalDetails interface.
 */
export function instanceOfAccountDetailsPersonalDetails(value: object): value is AccountDetailsPersonalDetails {
    if (!('emails' in value) || value['emails'] === undefined) return false;
    if (!('phones' in value) || value['phones'] === undefined) return false;
    if (!('addresses' in value) || value['addresses'] === undefined) return false;
    return true;
}

export function AccountDetailsPersonalDetailsFromJSON(json: any): AccountDetailsPersonalDetails {
    return AccountDetailsPersonalDetailsFromJSONTyped(json, false);
}

export function AccountDetailsPersonalDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDetailsPersonalDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'emails': json['emails'],
        'phones': json['phones'],
        'addresses': ((json['addresses'] as Array<any>).map(PersonalDetailsAddressesInnerFromJSON)),
    };
}

  export function AccountDetailsPersonalDetailsToJSON(json: any): AccountDetailsPersonalDetails {
      return AccountDetailsPersonalDetailsToJSONTyped(json, false);
  }

  export function AccountDetailsPersonalDetailsToJSONTyped(value?: AccountDetailsPersonalDetails | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'emails': value['emails'],
        'phones': value['phones'],
        'addresses': ((value['addresses'] as Array<any>).map(PersonalDetailsAddressesInnerToJSON)),
    };
}

